<template>
    <smu-header-items-transition>
        <div v-if="resetFn && saveFn && hasChanges">
            <smu-card-modal
                :title="$t('helpers.confirm.title')"
                icon="fa-light fa-trash-xmark"
                :has-close-button="false"
                :open="isModalOpen"
            >
                <div class="flex w-full flex-col lg:w-[35rem]">
                    <span class="my-3">
                        {{ $t("helpers.confirm.unsaved_changes_content") }}
                    </span>
                </div>
                <template #footerContent>
                    <div class="flex flex-wrap justify-center sm:justify-end">
                        <smu-button
                            color="secondary"
                            class="m-1"
                            @click="toggleModal(false)"
                        >
                            {{ $t("helpers.buttons.continue_edit") }}
                        </smu-button>
                        <smu-button
                            color="danger"
                            class="m-1"
                            @click="
                                toggleModal(false);
                                resetFn();
                            "
                        >
                            {{ $t("helpers.buttons.discard_changes") }}
                        </smu-button>
                    </div>
                </template>
            </smu-card-modal>
            <div class="flex h-full w-full text-sm text-white">
                <div
                    class="my-auto flex w-full text-left align-middle md:w-1/2 md:text-center"
                >
                    <span class="space-x-1">
                        <smu-icon icon="fa-light fa-triangle-exclamation" />
                        <span
                            class="text-wrap px-1 font-light tracking-tight text-white"
                        >
                            {{ $t("helpers.common.unsaved_changes") }}
                        </span>
                    </span>
                </div>
                <div
                    class="flex flex-1 items-center justify-end whitespace-nowrap"
                >
                    <div class="flex space-x-2">
                        <smu-button
                            class="h-8 border-none bg-text hover:bg-text-4 dark:bg-text dark:hover:bg-text-4"
                            size="sm"
                            @click="toggleModal"
                        >
                            {{ $t("helpers.buttons.discard") }}
                        </smu-button>
                        <smu-button
                            class="h-8 border-none"
                            color="primary"
                            size="sm"
                            :disabled="isLoading"
                            @click="saveFn"
                        >
                            <template #iconLeft>
                                <font-awesome-icon
                                    v-if="isLoading"
                                    icon="fa-light fa-circle-notch"
                                    spin
                                ></font-awesome-icon>
                            </template>

                            {{ $t("helpers.buttons.save") }}
                        </smu-button>
                    </div>
                </div>
            </div>
        </div>
    </smu-header-items-transition>
</template>

<script setup lang="ts">
const { hasChanges, saveFn, resetFn, isLoading, toggleModal, isModalOpen } =
    useUnsavedChangesHeader();
</script>
