<template>
    <smu-menu-dropdown v-if="languages" :items="languageKeys">
        <template #value>{{ locale.toUpperCase() }}</template>
        <template #item="{ item }">
            <div class="w-full p-3" @click="locale = item">
                {{ languages[item] }}
            </div>
        </template>
    </smu-menu-dropdown>
</template>

<script setup lang="ts">
const { locale } = storeToRefs(useLocalSettingsStore());

const { data } = storeToRefs(useUserStore());

const languages = computed(() => {
    if (data) {
        return data.value?.master.locales.reduce(
            (accumulator, current) => ({
                ...accumulator,
                [current.code]: current.name[locale.value],
            }),
            {},
        ) as Record<string, string>;
    }
});
const languageKeys = computed(() => {
    if (languages?.value) {
        return Object.keys(languages.value);
    }
});
</script>
